<template>
    <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / <router-link to="/support/cases">Cases</router-link> / {{caseDetail.complaint_no}}
        </h1>
      </v-flex>
      <v-flex xs12 md12 lg8>
        <v-simple-table>
          <tbody>
            <tr>
              <th class="fs-12 primary--text">Complaint No.</th>
              <th class="fs-12 fw400">{{ caseDetail.complaint_no }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Yellow case ID</th>
              <th class="fs-12 fw400 accent--text">{{ caseDetail.yellow_case_id }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Name</th>
              <th class="fs-12 fw400">{{ caseDetail.name }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Email</th>
              <th class="fs-12 fw400">{{ caseDetail.email }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Phone</th>
              <th class="fs-12 fw400">{{ caseDetail.phone }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Complaint</th>
              <th class="fs-12 fw400">{{ caseDetail.description }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Status</th>
              <th class="fs-12 accent--text">{{ caseDetail.status }}</th>
            </tr>
          </tbody>
        </v-simple-table>
        <h2 class="primary--text mt-10">Correspondence</h2>
        <p class="ma-0 primary--text mt-5"><b>Status</b></p>
        <v-select single-line outlined style="max-width: 400px;" :items="status_list" v-model="status" label="Status"></v-select>
        <p class="ma-0 primary--text mt-5"><b>Comment</b></p>
        <v-textarea single-line outlined v-model="comment" label="Type you comment here.."></v-textarea>
        <v-btn @click="save" class="primary black--text pl-10 pr-10 mt-5" x-large>Save</v-btn>
      </v-flex>
      <v-flex xs12 md12 lg4 class="pl-5 pr-5">
        <h2 class="ma-0 pa-0 mb-5">History</h2>
        <div v-for="item in caseDetail.history" :key="item.created" class="grey darken-4 pa-5 mt-5" style="border-radius: 5px;">
            <p class="primary--text ma-0 fs-12">{{ item.status }}</p>
            <p class="caption">{{ item.created | moment("dddd, MMMM Do YYYY")}}</p>
            <p>{{ item.comment }}</p>
        </div>
      </v-flex>
      </v-layout>
      </div>
</template>

<script>
import Axios from 'axios'
import { BASE_URL } from '../../config'
export default {
    data(){
        return{
            caseDetail : {},
            comment : "",
            status : "",
            status_list : ["New","Processing","Resolved"]
        }
    },
    mounted(){this.fetchCase()},
    methods : {
        async fetchCase(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/support/"+this.$route.params.id
            let {data} = await Axios.get(url)
            this.caseDetail = data
            this.$store.dispatch("SET_PROGRESS",false)
        },
        async save(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/support/add-comment/"+this.$route.params.id
            let payload = {
                status : this.status,
                comment : this.comment
            }
            let {data} = await Axios.post(url,payload)
            this.status = ""
            this.comment = ""
            this.$store.dispatch("SET_PROGRESS",false)
            this.fetchCase()
        }
    }
}
</script>

<style scoped>
    .fs-12{
        font-size: 12pt !important;
    }
    .fw400{
        font-weight: 400 !important;
    }
</style>